import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import "../scss/modules/header-home-component.scss"
import "bootstrap/dist/css/bootstrap.css"

class header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }

  toggle = () => {
    this.setState({
      active: !this.state.active,
    })
  }

  render() {
    const { active } = this.state
    return (
      <div
        className={
          active
            ? "header-component col-md-12 active"
            : "header-component col-md-12 "
        }
      >
        <div
          role="button"
          className={"burger"}
          onClick={() => this.toggle()}
          tabIndex={0}
        >
          <span className={"line1"}></span>
          <span className={"line2"}></span>
          <span className={"line3"}></span>
        </div>
        <ul className={"nav-home"}>
          <li>
            <Link className={"item-nav current"} to="/">
              Accueil
            </Link>
          </li>
          <li>
            <Link className={"item-nav"} to="/Examens">
              Les examens
            </Link>
          </li>
          <li>
            <Link className={"item-nav"} to="/equipe">
              L'équipe
            </Link>
          </li>
          <li>
            <Link className={"item-nav big"} to="/engagement">
              Nos engagements
            </Link>
          </li>
          <li>
            <Link className={"item-nav big"} to="/info">
              Infos pratiques
            </Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default header
