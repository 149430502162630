import React from "react"
import marked from "marked"
import Seo from "../components/seo"
import logo from "../images/logo_home.png"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Header from "../components/header-home"
import "../scss/modules/index-component.scss"
import FloatingBtn from "../components/floating-btn"

const IndexPage = data => (
  <div className={"index-page"}>
    <Seo
      title={data.data.allStrapiAccueil.nodes[0].SEO_titre}
      description={data.data.allStrapiAccueil.nodes[0].SEO_description}
    />
    <div className={"bg"}>
      <Img
        fluid={data.data.file.childImageSharp.fluid}
        alt={"hopital_claude_galien"}
        style={{ height: "100%", width: "100%" }}
        imgStyle={{ objectFit: "cover" }}
      />
    </div>
    <div className={"home-nav-component"}>
      <img src={logo} alt="logo-brand" className={"logo"} />

      <div className={"content-home"}>
        <div className={"main"}>
          <h1>{data.data.allStrapiAccueil.nodes[0].Intitule}</h1>
          <p>{data.data.allStrapiAccueil.nodes[0].titre}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: marked(data.data.allStrapiAccueil.nodes[0].description),
          }}
        ></div>
        <div className={"main-links"}>
          <a
            className={"item-main"}
            href={data.data.allStrapiUrl.nodes[0].PrendreRendeVous}
            target="_blank"
            rel="noreferrer"
          >
            Prendre rendez-vous en ligne
          </a>
          <a
            className={"item-main blue"}
            href={data.data.allStrapiUrl.nodes[0].ResultatExamen}
            target="_blank"
            rel="noreferrer"
          >
            Vos résultats d'examens
          </a>
        </div>
      </div>
      <Header></Header>
      <FloatingBtn/>
    </div>
  </div>
)

export default IndexPage

export const query = graphql`
  query QueryUrl {
    allStrapiUrl {
      nodes {
        ResultatExamen
        PrendreRendeVous
      }
    }
    allStrapiAccueil {
      nodes {
        Intitule
        titre
        description
        SEO_titre
        SEO_description
      }
    }
    file(relativePath: { eq: "bg_accueil.png" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
